.inventory-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 50%;
    max-width: 50%;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: 1540px) {
        flex: 1 1 60%;
        max-width: 54%;
    }


    &-header {
        width: 100%;
        height: 73px;
        background: url(../../image/topbar.png) no-repeat center;
        background-size: cover;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        // @media screen and (max-width: 1440px) {
        //     height: 53px;
        // }

        // @media screen and (max-width: 1440px) {
        //     height: 43px;
        // }

        &_left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 48px;
            padding-left: 20px;
            font-size: 28px;
            text-transform: uppercase;

            @media screen and (max-width: 1540px) {
                font-size: 22px;
            }


            &-frame {
                display: flex;
                gap: 20px;
                align-items: center;

                .energy,
                .health,
                .sdm {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    
                    img{
                        @media screen and (max-width: 1540px) {
                            width: 22px;
                        }
                    }

                }
            }

            .stamina {
                display: flex;
                gap: 8px;

                span {
                    color: greenyellow;
                }
            }
        }

        &_right {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            padding-right: 20px;

            img {
                cursor: pointer;

                @media screen and (max-width: 1540px) {
                    width: 22px;
                }

            }
        }
    }

    .inventory-block-bag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58%;
        margin-left: 30px;
        position: relative;

        .grid {
            display: grid;
            grid-template-columns: repeat(10, 72px);
            grid-template-rows: repeat(8, 72px);
            gap: 8px;
            width: fit-content;
            background: #00000008;
    
            @media screen and (max-width: 1540px) {
                grid-template-columns: repeat(10, 42px);
                grid-template-rows: repeat(8, 42px);
            }
          }
          
          .grid-item {
            width: 72px;
            height: 72px;
            border: 2px solid #FFFFFF12;
            background: #00000008;
            border-radius: 8px;

            
    
            @media screen and (max-width: 1540px) {
                width: 42px;
                height: 42px;
            }
          }

          .grid-item.highlight {
            background-color: red;
          }
          
          .bag-block {
            width: 282px;
            height: 428px;
            background-image: url("../../image/main_bag.png");
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            position: absolute;
            top: 45%;
            left: 45%;
            transform: translate(-50%, -50%);

            @media screen and (max-width: 1540px) {
                width: 160px;
                height: 245px;

                top: 45%;
                left: 47%;
            }
          }
          
          .bag-block img {
            width: 75px;
          }
      }
      

      
    //   @media screen and (max-width: 1540px) {
    //     .bag-block {
    //       width: 170px;
    //       height: 283px;
    //     }
    //   }
      

    .storage {
        display: flex;
        position: absolute;
        bottom: 60px;
        left: 0;
        background-image: url('../../image/storage.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 550px;
        height: 210px;
        z-index: 999;

        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

        img{
            width: 50px;
        }


        @media screen and (max-width: 1540px) {
            width: 450px;
            height: 170px;
            bottom: 30px;
        }

        @media screen and (max-width: 1440px) {
            width: 420px;
            height: 160px;
            bottom: 75px;
        }

    }

    .banjo {
        position: absolute;
        background: url(../../image/banjo.png) no-repeat;
        background-size: contain;
        width: 263px;
        height: 40%;
        position: absolute;
        bottom: 2%;
        right: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        @media screen and (max-width: 1540px) {
            width: 205px;
            right: 0;
            bottom: 0;
        }

        img {
            cursor: pointer;
            width: 50%;
            z-index: 999;
        }
    }

}

