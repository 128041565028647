.win-battle-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* затемнений фон */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color: #fff;

    &_title {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 64px;
      }

    .block-image {
        background: url('../../image/rays.png') no-repeat center;
        background-size: cover;
        width: 1051px;
        height: 524px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 1540px) {
            width: 910px;
            height: 460px;
        }
    }

    .block-paper {
        background: url('../../image/paper_background.png') no-repeat center;
        background-size: cover;
        width: 1023px;
        height: 321px;

        font-size: 42px;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 30px;

        @media screen and (max-width: 1445px) {
            width: 910px;
            height: 285px;
        }

        .rewards, .wins, .lives{
            padding: 0px 50px;
            -webkit-text-stroke: 1px #000;
        }

        .rewards{
            display: flex;
            gap: 40px;
            align-items: center;
        }
    }

    .continue-text {
        margin-top: 20px;
        font-size: 32px;
        cursor: pointer;
        color: white;
        /* колір тексту */

        &:hover {
            text-decoration: underline;
        }
    }
}