.tavern {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background: url('../../image/background_tavern.png') no-repeat center center fixed;
  background-size: cover;
  position: relative;

  justify-content: space-between;
  align-items: flex-start;
  // flex-wrap: wrap;
}