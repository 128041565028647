.arena {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: url('./../../image/background_fight.png') no-repeat center center fixed;
    background-size: cover;
    position: relative;

    &-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      height: 90%;
    }

    &-bags-container{
      display: flex;
      justify-content: space-around;

      .player-bag{
        width: 250px;
        height: 392px;

        @media screen and (max-width: 1540px) {
          width: 170px;
          height: 283px;
        }

        background-image: url('../../image/main_bag.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        img{
          width: 75px;
        }

        @media screen and (max-width: 1445px){
          width: 175px;
          height: 275px;
        }
      }

      .enemy-bag{
        width: 250px;
        height: 392px;

        @media screen and (max-width: 1540px) {
          width: 170px;
          height: 283px;
        }

        background-image: url('../../image/main_bag.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;

        img{
          width: 75px;
        }

        @media screen and (max-width: 1445px){
          width: 175px;
          height: 275px;
        }
      }
    }

    &-main-block {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 392px;
      position: relative;

      &_info {
        width: 100%;
        height: 207px;
        display: flex;
        justify-content: center;
        gap: 8px;
        position: relative;

        .player-object, .enemy-object {
          position: absolute;

          transition: transform 0.5s ease-out;
        }

        

        .player-object {
          top: -100%;
          left: 0;

           @media screen and (max-width: 1540px) {
            top: -36%;
            width: 201px;
            height: 375px;
          }
        }

        .enemy-object {
          top: -70%;
          right: 0;

         @media screen and (max-width: 1540px) {
           top: -6%;
            width: 285px;
            height: 310px;
          }
        }

        .player-object,
      .enemy-object {
        position: absolute;
        transition: transform 0.5s;
      }

      // .player-object.move {
      //   animation: moveLeftRight 2s infinite;
      // }

      // .enemy-object.move {
      //   animation: moveLeftRight 2s infinite;
      // }

      // @keyframes moveLeftRight {
      //   0%, 100% {
      //     transform: translateX(0);
      //   }
      //   50% {
      //     transform: translateX(-20px);
      //   }
      // }
    

        &-play {
          width: 375px;
          height: 110px;

            @media screen and (max-width: 1540px) {
              width: 340px;
          }

          background-image: url('../../image/play-box.png');
          background-repeat: no-repeat;
          background-size: contain;

          position: absolute;
          top: -25%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 18px;  

          &_top{
              width: 272px;
              height: 16px;
              background: #463D3B;
              border-radius: 8px;

              @media screen and (max-width: 1540px) {
                width: 245px;
              }

              .blue-line {
                display: inline-block;
                height: 100%;
                background: #9AFBFB;
                position: relative;
                border-radius: 8px;
              
                img {
                  position: absolute;
                  top: 50%; /* Center vertically */
                  right: -10px; /* Stick to the right edge */
                  transform: translateY(-50%); /* Adjust for centering vertically */
                }
              
              }
          }

          &_actions{
            display: flex;
            justify-content: center;
            gap: 40px;

            img{
              cursor: pointer;

              @media screen and (max-width: 1540px) {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .status-bar{
          display: flex;
          align-items: center;

          p{
            width: 105px;
          }
          .status-field{
            width: 100%;
            height: 32px;
            display: block;
            border: 2px solid #000000;
            background: #463D3B;
            border-radius: 8px;

            span{
              background-color: rgb(255, 0, 0);
              display: block;
              width: 0%;
              height: 100%;
              border-radius: 8px;
              overflow: hidden;
            }
      
          }
         
        }
        
        &-palyer {
          width: 462px;
          height: 100%;

          @media screen and (max-width: 1540px) {
             width: 415px;
          }

          background-image: url('../../image/arena-info-box.png');
          background-repeat: no-repeat;
          background-size: contain;

          color: white; /* Колір тексту */
          -webkit-text-stroke: 1px #000; /* Товщина і колір обводки */
          font-size: 24px;
          font-weight: 400;

          .player-nickname{
            margin: 13px 30px 40px 30px;
            display: inline-block;

            @media screen and (max-width: 1540px) {
              margin: 13px 30px 25px 30px;
            }
          }

         .player-health-info{
          margin: 13px 30px 40px 30px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .health{
            display: flex;
            align-items: center;
            gap: 20px;

              .health-field{
            width: 320px;
            height: 32px; 
            border: 2px solid black;
            border-radius: 8px;
            background: #463D3B;

            span{
              display: inline-block;
              background: #C3403A;
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
          }

          .stamina{
            display: flex;
            align-items: center;
            gap: 20px;

            .stamina-field{
              width: 320px;
              height: 32px; 
              border: 2px solid black;
              border-radius: 8px;
              background: #463D3B;

              span{
                border-radius: 8px;
                display: inline-block;
                background: #DDBC48;
                width: 100%;
                height: 100%;
              }
            }
          }
         }
        }

        &-enemy {
          width: 462px;
          height: 100%;

            @media screen and (max-width: 1540px) {
             width: 415px;
          }

          background-image: url('../../image/arena-info-box.png');
          background-repeat: no-repeat;
          background-size: contain;

          color: white; /* Колір тексту */
          -webkit-text-stroke: 1px black; /* Товщина і колір обводки */
          font-size: 24px;

          .enemy-nickname{
            margin: 13px 30px 40px 210px;
            display: inline-block;

             @media screen and (max-width: 1540px) {
              margin: 13px 30px 25px 200px;
            }
          }

          .enemy-health-info{
          margin: 13px 30px 40px 30px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .health{
            display: flex;
            align-items: center;
            gap: 20px;

              .health-field{
            width: 320px;
            height: 32px; 
            border: 2px solid black;
            border-radius: 8px;
            background: #463D3B;

            span{
              display: inline-block;
              background: #C3403A;
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
          }

          .stamina{
            display: flex;
            align-items: center;
            gap: 20px;

            .stamina-field{
              width: 320px;
              height: 32px; 
              border: 2px solid black;
              border-radius: 8px;
              background: #463D3B;

              span{
                border-radius: 8px;
                display: inline-block;
                background: #DDBC48;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        }

        .damage-display {
          position: absolute;
          font-size: 22px;
          font-weight: bold;
          opacity: 1;
          animation: fade-out 1s ease-out forwards;
          pointer-events: none;
          z-index: 999;
        
          &.player-damage {
            color: #00bfff;
            left: 0;
            bottom: 20%;
          }
        
          &.enemy-damage {
            color: #ff4500;
            right: 0;
            bottom: 20%;
          }
        }
      }
    }
  }
  


  
  @keyframes fade-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-30px);
      opacity: 0;
    }
  }
  
  .player-object, .enemy-object {
    transition: transform 0.5s ease-in-out;
  }
  
  .move-right {
    animation: moveRight 0.5s forwards;
  }
  
  .move-left {
    animation: moveLeft 0.5s forwards;
  }
  
  @keyframes moveRight {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(50px); // Adjust the distance as needed
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes moveLeft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-50px); // Adjust the distance as needed
    }
    100% {
      transform: translateX(0);
    }
  }
  