.shop-shells {
  display: flex;  
  height: 95vh;
  flex: 1 1 60%; 
  max-width: 60%; 
  box-sizing: border-box;

  @media screen and (max-width: 1540px) {
    flex: 1 1 50%; 
    max-width: 50%; 
  }

  @media screen and (max-width: 1440px) {
    flex: 1 1 85%;
    max-width: 85%;
  }



  .shop-shells-image-block{
    width: 100%;
  }

  &-image {
      background: url(../../image/shop_shells.png) no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      position: relative;

      .item1-container,
      .item2-container,
      .item3-container,
      .item4-container,
      .item5-container {
          position: absolute;
          width: fit-content;
      }

      .badge {
          position: absolute;
          right: -45%;
          width: 60%;
          height: 37px;
          background: url(../../image/shop/price_bage_red.png) no-repeat;
          background-size: contain;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 24px;
          font-weight: bold;

          @media screen and (max-width: 1540px) {
           font-size: 18px;
          }
      }

      .item1-container,.item2-container,.item3-container,.item4-container,.item5-container{
        .hover-box {
          position: absolute;
          width: 544px;
          height: 357px;
          z-index: 10;
          top: 10%;
          left: 53%;

          background-image: url(../../image/modal/modal_box_1.png);
          background-size: 100% 100%;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-self: center;

          @media screen and (max-width: 1540px) {
            width: 450px;
            height: 287px;
          }
      

          &_container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 85%;
            font-size: 28px;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1540px) {
              font-size: 22px;
            }
        
    
            span {
                color: yellow;
            }
        }
    
        &-title {
            padding: 5px 60px;
            text-align: center;
        }
    
        &-info {
            padding: 15px 60px;
    
            &_item{
                display: flex;
                gap: 8px;
            }
        }
    
        &-rarity {
            padding: 15px 60px 5px 60px;
        }
        }
      }
      
      .item1-container { 
        top: 15%;
        left: 18%;
        display: flex;
        width: 12%;
        height: 13%;
        align-items: flex-end;
        justify-content: center;

        // @media screen and (max-width: 1445px) {
        //   width: 100px;
        //   height: 110px;
        // }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain; 
          cursor: grab;
        }

        img:active {
          cursor: grabbing; 
        }
      }
      
      .item2-container { 
        top: 15%;
        left: 60%;
        display: flex;
        width: 12%;
        height: 13%;
        align-items: flex-end;
        justify-content: center;

        // @media screen and (max-width: 1445px) {
        //   width: 100px;
        //   height: 110px;
        // }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain; 
          cursor: grab;
        }

        img:active {
          cursor: grabbing; 
        }
      }
      
      .item3-container { 
        top: 28%;
        left: 45%;
        display: flex;
        width: 12%;
        height: 13%;
        align-items: flex-end;
        justify-content: center;

        // @media screen and (max-width: 1445px) {
        //   width: 100px;
        //   height: 110px;

        //   top: 29%;
        // }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain; 
          cursor: grab;
        }

        img:active {
          cursor: grabbing; 
        }
      }
      
      .item4-container { 
        top: 42%;
        left: 25%;
        display: flex;
        width: 12%;
        height: 13%;
        align-items: flex-end;
        justify-content: center;

        // @media screen and (max-width: 1445px) {
        //   width: 100px;
        //   height: 110px;
        // }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain; 
          cursor: grab;
        }

        img:active {
          cursor: grabbing; 
        }
      }
      
      .item5-container { 
        top: 62%;
        left: 15%;
        display: flex;
        width: 12%;
        height: 13%;
        align-items: flex-end;
        justify-content: center;

        @media screen and (max-width: 1440px) {
          top: 60%;
      }

        img{
          width: 100%;
          height: 100%;
          object-fit: contain; 
          cursor: grab;
        }

        img:active {
          cursor: grabbing; 
        }
      }
      
  }

  .refresh {
      position: absolute;
      bottom: 0;
      left: 0;
      background: url(../../image/refresh.png) no-repeat;
      // width: 274px;
      // height: 274px;
      width: 19%;
      height: 26%;
  
      background-size: contain; 
      cursor: pointer;

      // @media screen and (max-width: 1445px) {
      //   width: 174px;
      //   height: 174px;
      // }

      &-content {
          display: inline-block;
          font-size: 28px;
          color: #ffffff;
          position: absolute;
          top: 17%;
          left: 46%;

          @media screen and (max-width: 1540px) {
            top: 17%;
            left: 40%;

            font-size: 20px;
          }

          @media screen and (max-width: 1440px) {
            top: 17%;
            left: 53%;

            font-size: 20px;
          }

          .score {
              display: flex;
              align-items: center;
              gap: 3px;
              

              img {
                  width: 44px;
                  height: 44px;

                  @media screen and (max-width: 1540px) {

                   width: 22px;
                   height: 22px;
                  }
              }
          }
      }

      img {
          width: 100%;
      }
  }
}
