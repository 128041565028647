.flip-page {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.flip-page > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
